<template>
    <div
        class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100 menu-tree-manager"
    >
        <div class="ui-layout-content--full ui-form-actions">
            <el-button
                type="primary"
                size="mini"
                @click="handleAdd('inLevel')"
            >{{'添加（同级）'}}</el-button>
            <el-button
                type="primary"
                size="mini"
                :disabled="!currentNodeId"
                @click="handleAdd('nextLevel')"
            >{{'添加（下级）'}}</el-button>
            <el-button
                type="primary"
                size="mini"
                :disabled="!currentNodeId"
                @click="handleEdit"
            >{{'编辑'}}</el-button>
            <el-button
                type="primary"
                size="mini"
                :disabled="!currentNodeId"
                :loading="dialog.deleteSubmiting"
                @click="handleDelete"
            >{{'删除'}}</el-button>
            <el-button
                type="primary"
                size="mini"
                @click="handleReloadCaching"
            >{{'刷新所有权限缓存'}}</el-button>
        </div>
        <div class="menu-tree">
            <el-tree
                v-loading="menuTreeLoading"
                :data="menuList"
                default-expand-all
                :expand-on-click-node="false"
                class="menu-tree"
                @current-change="handleMenuTreeChange"
            >
              <span slot-scope="{ node, data }" class="el-tree-node__label">

                {{data.text}}
              </span>
            </el-tree>
        </div>
        <el-dialog
            width="700px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <menu-management-form
                ref="editForm"
                :id="dialog.currentNodeId"
                :parentId="dialog.parentId"
                :language="languageList"
                :type="dialog.type"
                :submiting="dialog.formSubmiting"
                v-if="dialog.visible"
            ></menu-management-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">{{'取消'}}</el-button>
                <el-button
                    type="primary"
                    @click="submitHandle"
                    :loading="dialog.formSubmiting"
                    size="mini"
                >{{'确定'}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import MenuManagementForm from './MenuManagementForm';
export default {
    name: 'MenuManagement',
    components: {
        MenuManagementForm
    },
    data() {
        return {
            dialog: {
                visible: false,
                title: '',
                currentNodeId: '',
                parentId: '',
                type: '',
                formSubmiting: false,
                deleteSubmiting: false
            },
            menuTreeLoading: true,
            menuList: [],
            currentNodeId: '',
            parentNodeId: '',
            languageList: []
        };
    },
    methods: {
        //添加
        handleAdd(type) {
            if (type === 'inLevel') {
                // 同级
                this.dialog.parentId = this.parentNodeId;
            } else {
                // 下级
                this.dialog.parentId = this.currentNodeId;
            }
            this.dialog.currentNodeId = this.currentNodeId;
            this.dialog.type = 'add';
            this.dialog.title = '添加菜单';
            this.dialog.visible = true;
        },
        handleEdit() {
            this.dialog.currentNodeId = this.currentNodeId;
            this.dialog.parentId = this.parentNodeId;
            this.dialog.type = 'edit';
            this.dialog.title = '编辑菜单';
            this.dialog.visible = true;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        async handleDelete() {
            this.dialog.deleteSubmiting = true;
            try {
                await this.$client.deleteMenu([this.currentNodeId]);
                this.handleQueryInit();
                this.$message({
                    message: this.$t('common.operateSuccess'),
                    type: 'success'
                });
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: this.$t('common.operateFailed')
                });
            }

            this.dialog.deleteSubmiting = false;
        },
        //刷新
        handleReloadCaching() {
            this.$client
                .reloadAllCaching()
                .then(() => {
                    this.$message({
                        message: '刷新成功',
                        type: 'success'
                    });
                    this.handleQueryInit();
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: '刷新失败'
                    });
                });
        },
        handleMenuTreeChange(row) {
            this.currentNodeId = row.id;
            this.parentNodeId = row.parentId;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(() => {
                this.closeModalHandle();
                this.handleQueryInit();
            });
        },
        async handleQueryInit() {
            this.menuTreeLoading = true;
            const getMenu = this.$client.getMenuTree();
            const menu = await getMenu;
            this.menuList = menu;
            this.menuTreeLoading = false;
        }
    },
    created() {
        this.handleQueryInit();
    }
};
</script>
<style lang="less" scoped>
.menu-tree-manager {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .menu-tree {
        flex: 1;
        overflow: auto;
        /deep/ .tree-icon {
            padding: 0 5px;
        }

        /deep/ .fa-folder-o-open {
            &::before {
                content: '\f115';
            }
        }

        /deep/ .el-tree-node__content {
            line-height: 40px;
            height: 40px;
            border-bottom: 1px solid #ebeef5;
        }

        /deep/ .el-tree-node.is-current > .el-tree-node__content,
        /deep/ .el-tree-node.is-current:focus > .el-tree-node__content {
            background-color: #fbf4c3;
        }

        .fa.folder {
            &::before {
                content: '\f114';
            }

            &.expanded {
                &::before {
                    content: '\f115';
                }
            }
        }
    }

    .dialog {
        /deep/ .el-dialog__body {
            padding-top: 0;
        }
    }
}
</style>
