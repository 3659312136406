<template>
    <div>
        <el-form :model="formBasic" :rules="basicRules" ref="form" size="mini">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'地址'" prop="code">
                        <el-input v-model="formBasic.uri" style="width: 100%"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'父级地址'"
                        prop="parentUri"
                    >
                        <el-input
                            v-model="formBasic.parentUri"
                            style="width: 100%"
                            :disabled="type === 'add'"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item
                        :label="'语言类别'"
                        prop="languageCode"
                    >
                        <el-select
                            v-model="formBasic.languageCode"
                             style="width: 100%"
                            :placeholder="'请选择'"
                            filterable
                            allow-create
                        >
                            <el-option
                                v-for="item in keyValues.languages"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        :label="'名称'"
                        prop="name"
                        style="width:100%"
                    >
                        <el-input
                            v-model="menuLanguage.name"
                            style="width:100%"
                            @change="changeName"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item
                        :label="'菜单名'"
                        prop="menuViewName"
                    >
                        <el-input
                            v-model="menuLanguage.menuViewName"
                            @change="changeMenuViewName"
                        ></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item
                        :label="'标签名'"
                        prop="tabsViewName"
                    >
                        <el-input
                            v-model="menuLanguage.tabsViewName"
                            @change="changeTabsViewName"
                        ></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row :gutter="24">

                  <el-col :span="12">
                    <el-form-item :label="'排序'" prop="rank">
                        <el-input-number
                            v-model="formBasic.rank"
                            size="small"
                            style="width:100%"
                            :min="0"
                        ></el-input-number>
                    </el-form-item>
                </el-col>
                 <el-col :span="12">
                    <el-form-item :label="'是否显示'" prop="isShow">
                        <el-col>
                            <el-radio
                                v-model="formBasic.isShow"
                                :label="true"
                            >{{'是'}}</el-radio>
                            <el-radio
                                v-model="formBasic.isShow"
                                :label="false"
                            >{{'否'}}</el-radio>
                        </el-col>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'MenuManagementForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        parentId: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            result: [],
            menuTab: 'formBasic',
            formBasic: {
                parentId: this.parentId,
                name: '',
                tabsViewName: '',
                menuViewName: '',
                languageCode: '',
                menuLanguages: [
                    {languageCode: this.formBasic.languageCode, name: '', menuViewName: '', tabsViewName: ''}
                ],
                rank: '',
                isShow: '',
                parentUri: ''
            },
            basicRules: {
                languageCode: [
                    {
                        required: true,
                        message: '请输入语言类别',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                tabsViewName: [
                    {
                        required: true,
                        message: '请输入标签名',
                        trigger: 'blur'
                    }
                ],
                menuViewName: [
                    {
                        required: true,
                        message: '请输入菜单名',
                        trigger: 'blur'
                    }
                ],
                uri: [
                    {
                        required: true,
                        message: '请输入地址',
                        trigger: 'blur'
                    }
                ],
                parentUri: [
                    {
                        required: false,
                        message: '请选择父级地址',
                        trigger: 'blur'
                    }
                ]
            },
            keyValues: {
                languages: []
            }
        };
    },
    computed: {
        menuLanguage() {
            const menuLanguages = this.formBasic.menuLanguages.filter(e => e.languageCode === this.formBasic.languageCode);
            return menuLanguages[0];
        }
    },
    methods: {
        async bindKeyValues() {
            const data = await this.$client.getLanguages();
            this.keyValues.languages = data;
        },
        async initFormValue() {
            await this.bindKeyValues();
            await this.getmenuArray();
        },
        async getmenuArray() {
            this.$client.getMenuTree().then(data => {
                for (let i = 0; i < data.length; i++) {
                    this.result.push({uri: data[i].entity.uri, id: data[i].id, parentId: data[i].parentId});
                    this.flat(data[i].children);
                }
                const {type, id, parentId} = this;
                this.formBasic.parentId = parentId;
                if ((type === 'edit' || type === 'detail') && id != null) {
                    this.getMenuById(this.id);
                } else {
                    const parentUri = this.result.filter(i => i.id === parentId);
                    if (parentUri.length < 1) {
                        this.formBasic.parentUri = '';
                    } else {
                        this.formBasic.parentUri = parentUri[0].uri;
                    }
                }
            });
        },
        changeName(name) {
            this.formBasic.name = name;
        },
        changeTabsViewName(name) {
            this.formBasic.tabsViewName = name;
        },
        changeMenuViewName(name) {
            this.formBasic.menuViewName = name;
        },
        flat(nodes) {
            if (!nodes || nodes.length === 0) {
                return [];
            }
            nodes.forEach(node => {
                this.result.push({uri: node.entity.uri, id: node.id, parentId: node.parentId});
                return this.flat(node.children);
            });
        },
        getMenuById(id) {
            this.$client.getMenuById(id).then(data => {
                if (data.menuLanguages.length > 0) {
                    data.languageCode = this.keyValues.languages[0];
                } else {
                    data.languageCode = '';
                    data.menuLanguages = [{languageCode: this.formBasic.languageCode, name: '', menuViewName: '', tabsViewName: ''}];
                }
                this.changeName(data.menuLanguages[0].name);
                this.changeTabsViewName(data.menuLanguages[0].tabsViewName);
                this.changeMenuViewName(data.menuLanguages[0].menuViewName);

                this.formBasic = {
                    ...this.formBasic,
                    ...data
                };
                const a = this.result.filter(i => i.id === data.parentId);
                if (a.length < 1) {
                    this.formBasic.parentUri = '';
                } else {
                    this.formBasic.parentUri = a[0].uri;
                }
            });
        },
        submitHandle(cb) {
            this.formBasic.parentId = '';
            if (this.formBasic.parentUri) {
                const pCode = this.result.filter(i => i.uri === this.formBasic.parentUri);
                if (pCode.length < 1) {
                    this.$message({
                        message: this.$t('menuManagement.parentCodeNone'),
                        type: 'error'
                    });
                    return;
                }
                this.formBasic.parentId = pCode[0].id;
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    const {type} = this;
                    if (type === 'add') {
                        this.$client.addMenu(this.formBasic).then(() => {
                            this.$message({
                                message: this.$t('common.operateSuccess'),
                                type: 'success'
                            });
                            cb();
                        });
                    } else if (type === 'edit') {
                        this.$client.editMenu(this.formBasic).then(() => {
                            this.$message({
                                message: this.$t('common.operateSuccess'),
                                type: 'success'
                            });
                            cb();
                        });
                    } else {
                        this.$message({
                            message: this.$t('menuManagement.typeError'),
                            type: 'error'
                        });
                    }
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
</style>
